import Glide from '@glidejs/glide'
import { Controls } from '@glidejs/glide/dist/glide.modular.esm'

const start = () => {
	// use dynamic import to lazy load JS, so it's non-blocking.
	//import('./carousel.js').then(carousel => carousel.init());
	import('./modal.js').then(modals => modals.init())
	import('./video.js').then(video => video.init())
	import('./google-maps/base.js').then(maps => maps.init())

	// import Glider and modules styles
	new Glide('.glide-home', {
		autoplay: 5000,
		type: 'slider',
		perView: 1,
		focusAt: '0'
	}).mount()
	new Glide('.glide-life-at-genting', {
		type: 'carousel',
		autoplay: 3000,
		perView: 4,
		focusAt: '0',
		breakpoints: {
			800: {
				perView: 2
			},
			480: {
				perView: 1
			}
		}
	}).mount({ Controls })
	new Glide('.glide-our-values', {
		type: 'carousel',
		perView: 5,
		focusAt: 'center',
		breakpoints: {
			800: {
				perView: 3
			},
			480: {
				perView: 1
			}
		}
	}).mount({ Controls })
}

start()
